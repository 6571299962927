import $ from 'jquery';
import 'slick-carousel';
import { register } from 'src/brandless/ujs';
import '../../bookmark_button/chunk';
import '../../event_datetime/chunk';
import '../../follow_button/chunk';

register('.wondr-pick-carousel__slides', ({ element }) => {
  const prevArrow = $('[data-arrow-prev]');
  const nextArrow = $('[data-arrow-next]');
  const totalItems = $('.wondr-pick-carousel__slide-item').length;
  const initialSlide = Math.floor(Math.random() * totalItems);

  $('.wondr-pick-carousel__slides').slick({
    slidesToShow: 1,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    swipeToSlide: true,
    infinite: true,
    rows: 0,
    dots: true,
    autoplay: true,
    initialSlide: initialSlide,
  });
});
