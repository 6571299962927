import { register } from 'src/brandless/ujs';
import Swiper from 'swiper';

class VideoCarousel {
  private swiper;

  constructor(private readonly component: string) {
    this.swiper = new Swiper(component, {
      slidesPerView: 1,
      spaceBetween: 24,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        1128: {
          slidesPerView: 3,
        },
      },
    });

    this.destroy = this.destroy.bind(this);
  }

  public destroy(): void {
    this.swiper.destroy();
  }
}

register('[data-video-carousel]', ({ element }) => {
  const swiper = new VideoCarousel('[data-video-carousel]');
});
